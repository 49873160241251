<template>
  <section v-if="isReady" class="zq-card-row">
    <div class="zq-card-row--label">
      <div class="overview-label">Action Adjustment Factors:</div>
    </div>
    <div class="zq-card-row--content">
      <div v-for="(actionType, index) in actionTypeAdjustmentFactors" :key="index">
        {{ actionType.name }} : {{ actionType.value }}
      </div>
    </div>
  </section>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: 'ZqActionTypeAdjustmentFactorsView',
  data() {
    return {
      actionTypes: [],
      actionTypeAdjustmentFactors: [],
      isReady: false,
    }
  },
  props: {
    formData: Object,
  },
  async created() {
    const ids = this.formData.actionTypeAdjustmentFactors.map(v => v.actionTypeId);
    this.actionTypes = await this.handleGetActionTypesByQuery({
      queryRequest: {
        must: [
          {
            queryField: 'id',
            queryValues: ids
          }
        ]
      }
    });

    this.actionTypes.forEach(a => {
      const idx = this.formData.actionTypeAdjustmentFactors.findIndex(c => c.actionTypeId === a.id);
      if (idx !== -1) {
        this.actionTypeAdjustmentFactors.push({
          name: a.name,
          value: this.formData.actionTypeAdjustmentFactors[idx].adjustmentFactor
        });
      }
    })

    this.isReady = true;
  },
  methods: {
    ...mapActions('actionTypes', ['handleGetActionTypesByQuery']),
  },
};
</script>
